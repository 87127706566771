/******************************(unit-research-page.template.tsx)*******************************/
import { graphql } from "gatsby";
import { UnitResearchPageProps } from "../schema/models/unit-research-page.model";
import { UnitResearchDocument } from "../schema/models/unit-research-document.model";

import React from "react";
import UnitResearchPage from "../components/unit-research-page/unit-research-page.component"

export const UnitResearchPageTemplate = ({ data }) => {
  const UnitResearchPageDocument = new UnitResearchDocument(
    data.prismicUnitResearch.dataRaw
  );
  return (
    <UnitResearchPage
      {...new UnitResearchPageProps(UnitResearchPageDocument, null)}
    />
  );
};
  
export const query = graphql`
  query($id: String!) {
    prismicUnitResearch(id: { eq: $id }) {
      dataRaw
    }
  }
`;

export default UnitResearchPageTemplate;
